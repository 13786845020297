<template>
    <div id="gallery-section">
        <div v-if="(userId && gallery.length) || !userId" class="gallery-content">
            <div class="my-gallery_header">
                <div class="my-gallery_title">
                    фотографии
                </div>
                <div class="my-gallery_controls" v-if="!userId">
                    <button class="upload-image def-btn" @click="showUploadImageModal()">Загрузить</button>
                </div>
            </div>
            <div class="my-gallery_photos" ref="my-gallery_photos">
                <div @click="showImageSliderModal(index)" :class="`img-wrapper item_grid_${i.photos.length} ${i.photos.length > 4 ? 'item_grid_4' : ''}`" v-for="(i, index) in gallery" :key="index">
                    <!--<img draggable="false" :src="ii.photo_parsed_min" alt="def" v-for="(ii, inner_index) in i.photos" :key="inner_index">-->
                    <div class="image" v-for="(ii, inner_index) in i.photos" :key="inner_index" v-if="inner_index <= 3">
                        <img draggable="false" :src="ii.photo_parsed_min" alt="">
                        <loading class="x2 absolute centered" v-if="!ii.photo_parsed_min"></loading>
                    </div>
                    <span class="more-indicator" v-if="i.photos.length > 4">+{{i.photos.length - 4}}</span>
                    <span class="post-controls" v-if="!userId">
                        <span class="share-icon" @click.stop="shareGalleryPost(i, index)">
                            <i class="fas fa-share-alt"></i>
                        </span>
	                    <span class="edit-icon" @click.stop="editGalleryPost(i, index)">
                            <i class="far fa-edit" ></i>
                        </span>
                        <span class="delete-icon" @click.stop="deleteGalleryPost(i, index)">
                            <i class="far fa-trash-alt" ></i>
                        </span>
                    </span>
                </div>

                <button v-if="canLoadMorePhotos" :disabled="galleryLoading" class="load-more gallery-view-toggle def-btn has-loading-grid" @click="loadMore(true)">
                    Загрузить еще
                    <loading :reverse="true" v-if="galleryLoading"></loading>
                </button>

            </div>
        </div>
        <imageSlider ref="imageSlider" :img-index="img_index" :img-array="gallery" :has-comments-section="true" :is-slider="true" @loadMore="loadMore()" :has-controls="true"></imageSlider>
        <upload-image-modal ref="upload-image-modal" @imagesAdded="imagesAdded" @imageEdited="imageEdited"></upload-image-modal>
	    <share-photos-modal ref="share-photos-modal"></share-photos-modal>
    </div>
</template>

<script>
    import {usersService} from "../../_api";
    import imageSlider from "./ImageSlider";
    import UploadImageModal from "./modals/_UploadImageToGallery";
    import {handleAWSImage} from "../../_helpers/aws-handler";
    import loading from "./Loading";
    import SharePhotosModal from "@/components/_shared/modals/_SharePhotosModal";

    export default {
        name: 'gallerySection',
        props: ['userId'],
        data() {
            return {
                user_gallery: false,
                img_index: 0,
                arr: [],
                isProduction: process.env.NODE_ENV === 'production',
                gallery: [],
                galleryPage: 0,
                galleryPerPage: 9,
                galleryTotal: 0,
                galleryLoading: false
            }
        },
        components: {
	        SharePhotosModal,
            UploadImageModal,
            imageSlider,
            loading
        },
        beforeCreate() {},
        created() {},
        mounted() {
            this.getGallery(false);
        },
        watch: {
            userId: function(val){
                this.gallery = [];
                this.galleryPage = 0;
                this.galleryPerPage = 9;
                this.galleryTotal = 0;
                this.getGallery(false);
            },
            gallery: function (val) {}
        },
        computed: {
            canLoadMorePhotos() {
                return this.gallery.length && (this.galleryTotal - this.gallery.length) > 0
            }
        },
        methods: {
            imagesAdded(data) {
                this.getGallery(data);
            },
            imageEdited(data) {
                let index = this.gallery.findIndex(x => x.id === data.id);
                this.gallery[index] = Object.assign({}, this.gallery[index], data);
                this.gallery = [...this.gallery];
            },
            loadMore(scroll) {
                this.getGallery(false, scroll);
            },
            getGallery(added, scroll) {
                this.galleryLoading = true;
                (!this.galleryPage) ? this.galleryPage = 1 : null;
                usersService.getGalleryAPI({
                    post_id: added ? added : null,
                    user_id: this.userId,
                    page: (!this.galleryPage) ? 1 : this.galleryPage,
                    perPage: this.galleryPerPage
                }).then(res => {
                    if (added) {
                        let c = 0;
                        res.result['object_id'] = res.result.id;
                        res.result.photos.forEach((o) => {
                            o['photo_parsed'] = null;
                            o['photo_parsed_min'] = null;
                            handleAWSImage(o.photo).then(response => {
                                o['photo_parsed'] = response;
                                o['photo_parsed_min'] = response;
                                /*c += 1;
                                if (c === res.result.photos.length) {
                                    // (this.gallery.length % 3 === 0) ? this.gallery.pop() : null;
                                    this.gallery.unshift(res.result);
                                    this.galleryLoading = false;
                                }*/
                            });
                        });
                        this.gallery.unshift(res.result);
                        this.galleryLoading = false;
                    } else {
                        this.galleryTotal = res.total;
                        res.result.forEach((o) => {
                            o['object_id'] = o.id;

                            if (!o.photos.length) {
                                this.gallery.push(o);
                            } else {
                                let c = 0;
                                o.photos.forEach((obj, index) => {
                                    obj['photo_parsed'] = null;
                                    obj['photo_parsed_min'] = null;
                                    handleAWSImage(obj.photo_215 || obj.photo).then(response => {
                                        if (obj.photo_215) {
                                            obj['photo_parsed_min'] = response;
                                            handleAWSImage(obj.photo).then(response_c => {
                                                obj['photo_parsed'] = response_c;
                                            })
                                        } else {
                                            obj['photo_parsed'] = response;
                                            obj['photo_parsed_min'] = response;
                                        }

                                        c += 1;
                                        if (c === o.photos.length) {
                                            this.gallery.push(o);
                                            this.gallery.sort((a,b) => {
                                                return this.moment(b.created_at) - this.moment(a.created_at);
                                            });
                                            setTimeout(() => {
                                                if (scroll) {
                                                    this.$refs['my-gallery_photos'].scrollTop = this.$refs['my-gallery_photos'].scrollHeight;
                                                }
                                                this.galleryLoading = false;
                                            }, 250);
                                        }
                                    });
                                })
                            }

                        });
                        if (res.result.length > 0) {
                            this.galleryPage += 1;
                            this.galleryLoading = false;
                        }

                        console.log('TOTAL: ' + this.galleryTotal);
                        console.log('TOTAL: ' + this.gallery.length);

                        this.galleryLoading = false;
                    }
                })
            },
            deleteGalleryPost(data, index) {
                let q = confirm('Are you sure?');
                if (q) {
                    usersService.deleteGalleryPostAPI({
                        post_id: data.id
                    }).then(res => {
                        // console.log(res);
                        this.gallery.splice(index, 1);
                        this.galleryTotal -= 1;
                        this.$store.dispatch('alert/success', 'Вы успешно удалили пост из галереи', { root: true });
                    })
                }
            },
            shareGalleryPost(data, index) {
                console.log(data);
                console.log(this.$refs);
                // this.showUploadImageModal(data);
	            this.$refs['share-photos-modal'].showUsersModal(data);
            },
	        editGalleryPost(data, index) {
                console.log(data);
                this.showUploadImageModal(data);
            },
            showUploadImageModal(edit) {
                this.$refs['upload-image-modal'].showModal(edit);
            },
            showImageSliderModal(index) {
                console.log(index);
                this.img_index = index;
                this.$refs['imageSlider'].open(index);
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../assets/styles/_vars.less";
    #gallery-section {
        .gallery-content {
            width: 100%;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 1px;
            margin-bottom: 34px;
            background: #fff;
            padding: 10px 10px 25px 10px;
            position: relative;
            .my-gallery_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 5px 10px 5px;
                .my-gallery_title {
                    font-weight: 500;
                    text-transform: uppercase;
                    font-size: 16px;
                }
                .my-gallery_controls {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    button {
                        &:first-child {
                            margin-right: 10px;
                        }
                    }
                }
            }
            .my-gallery_photos {
                max-height: 500px;
                overflow-y: auto;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 5px;
                grid-auto-flow: row dense;
                .img-wrapper {
                    height: 150px;
                    overflow: hidden;
                    margin: 5px;
                    border-radius: 5px;
                    display: grid;
                    grid-gap: 1px;
                    grid-template-columns: 100% 100%;
                    grid-template-rows: 100% 100%;
                    position: relative;
                    border: 1px solid lightgrey;
                    image-orientation: from-image;
                    .image {
                        position: relative;
                    }
                    &.item_grid_2 {
                        grid-template-columns: 50% 50%;
                    }
                    &.item_grid_3 {
                        grid-template-columns: repeat(3, 1fr);
                    }
                    &.item_grid_4 {
                        grid-template-columns: 50% 50%;
                        grid-template-rows: 50% 50%;
                    }
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        animation: fade 1000ms;
                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -o-user-select: none;
                        user-select: none;
                    }
                    .more-indicator {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(0,0,0,0.7);
                        color: white;
                        padding: 0 10px;
                        font-size: 12px;
                        border-top-left-radius: 5px;
                    }
                    &:hover {
                        .post-controls {
                            right: 0;
                            opacity: 1;
                        }
                    }
                    .post-controls {
                        position: absolute;
                        top: 0;
                        cursor: pointer;
                        right: -20px;
                        opacity: 0;
                        transition: opacity, right 250ms;
                        background-color: rgba(0,0,0,0.7);
                        padding: 0 5px;
                        border-bottom-left-radius: 5px;
                        .fa-trash-alt, .fa-edit, .fa-share-alt {
                            color: #ffffff;
                        }
                        .fa-edit, .fa-share-alt {
                            margin-right: 5px;
                        }
                    }
                }
                .load-more {
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    margin-left: -56px;
                }
            }
        }
    }

    @keyframes fade {
        from { opacity: 0; }
        to   { opacity: 1; }
    }



    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #gallery-section {
            .gallery-content {
                .my-gallery_header {
                    .my-gallery_title {}
                    .my-gallery_controls {}
                }
                .my-gallery_photos {
                    grid-template-columns: repeat(2, 1fr);
                    .img-wrapper {
                        .post-controls {
                            opacity: 1;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 350px) {
        #gallery-section {
            .gallery-content {
                .my-gallery_header {
                    .my-gallery_title {}
                    .my-gallery_controls {}
                }
                .my-gallery_photos {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
    }
</style>

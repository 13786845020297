<template>
    <div>
        <div id="user-profile" v-if="user_data">
            <div class="user-profile-header" :class="{'has-back-btn': fromRoute}">
                <div class="my-profile-header__backbutton">
                    <img src="../../../assets/img/back.svg" alt="back" v-if="fromRoute" @click="backToPrevRoute()">
                </div>
                <div class="my-profile-header__controls" v-if="user_data">
                    <button :class="{'def-btn': user_data.is_friend, 'def-btn disabled': !user_data.is_friend}" :disabled="!user_data.is_friend" @click="askQuestion()">Задать вопрос</button>
	                <StartChatBtn :data="user_data"></StartChatBtn>
                    <button :disabled="user_data.is_friend_request === 1" v-if="user_data && !user_data.is_friend" :class="{'def-btn': !user_data.is_friend_request, 'def-btn disabled': user_data.is_friend_request}" @click="sendFriendRequest()">
                        {{(user_data.is_friend_request) ? 'Запрос отправлен' : 'Добавить в Друзья Up&Up'}}
                    </button>
                    <button v-if="user_data && user_data.is_friend" class="def-btn cancel-action" @click="removeFromFriends()">Удалить из друзей Up&Up</button>
                </div>
            </div>
            <div class="user-profile-info-content" >
                <div class="content-block" v-if="user_data">
                    <div class="column">
                        <avatar :width="111" :height="111" :img="user_data.avatar_parsed"></avatar>
                        <div class="user-fullname">
                            <span v-if="user_data">{{user_data.name}}</span>
                        </div>
                        <div class="user-description" >
                            <span v-if="user_data.birthday">{{user_data.location || user_data.occupations && user_data.occupations.length ? user_data.birthday + ', ' : user_data.birthday}}</span>
                            <span v-if="user_data.age_range">{{user_data.location || user_data.occupations && user_data.occupations.length ? (user_data.age_range.from + ' - ' + user_data.age_range.to) + ', ' : (user_data.age_range.from + ' - ' + user_data.age_range.to)}}</span>
                            <span v-if="user_data.location">{{user_data.occupations && user_data.occupations.length ? user_data.location.city.name + ', ' : user_data.location.city.name}}</span>
                            <span v-if="user_data.occupations && user_data.occupations.length">{{user_data.occupations[0].name}}</span>
                        </div>

                        <person-progress height="4" :green="user_data.similar_answers_percent" :red="user_data.diff_answers_percent" :gray="user_data.not_answered_percent"></person-progress>

                        <div class="arrow-link link" @click="changetab(tabs[1])">
                            <span><span class="green-text quantity">{{user_data.similar_count}}</span> схожих ответов</span>
                        </div>
                        <div class="arrow-link link" @click="changetab(tabs[2])">
                            <span><span class="quantity">{{user_data.difference_count}}</span> несхожих ответов</span>
                        </div>
                        <div hidden class="arrow-link">
                            <span @click="goToMyNotAnswered()" class="link"><span class="quantity">{{user_data.not_answered_by_me}}</span> неотвеченных вами</span>
                            <span class="arrow"></span>
                            <span class="desc">Ответьте на эти вопросы, чтобы увидеть совпадение по ним</span>
                        </div>
                        <div class="arrow-link link">
                            <span><span class="quantity">{{user_data.not_answered_count}}</span> неотвеченных {{user_data.name}}</span>
                        </div>
                        <div class="underline-link-wrapper">
                            <div class="underline-controls underline-link" @click="showPQModal()">{{user_data.personal_question_count + ' персональных'}}</div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="column-title">
                            <span class="title">Совпадение по темам</span>
                        </div>
                        <div class="column-item" v-for="item in user_data.topics_counter" :class="{'link-item': (item.similar !== 0 || item.difference !== 0) }" @click="loadingSimilar ? null : openModalWithSimilarQ(item)">
                            <div class="left-side">
                                <div class="property-value">{{item.topic_name}}</div>

                                <person-progress height="4" :green="item.similar_percent" :red="item.difference_percent" :gray="item.not_answered_percent" specificClass="person-progress-topics"></person-progress>
                            </div>
                            <div class="right-side">
                                <span v-if="item.similar !== 0" class="similar">{{item.similar}}</span>
                                <span v-if="item.difference !== 0" class="difference">{{item.difference}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="column-title">
                            <span class="title">Данные пользователя</span>
                        </div>
                        <div class="column-item" v-if="user_data.email">
                            <div class="left-side">
                                <div class="property-name">E-mail</div>
                                <div class="property-value">{{user_data.email}}</div>
                            </div>
                            <div class="right-side">
                            </div>
                        </div>
	                    <div class="column-item" v-if="user_data.sex">
		                    <div class="left-side">
			                    <div class="property-name">Пол</div>
			                    <div class="property-value">{{(user_data.sex === 'm') ? 'Мужчина' : 'Женщина'}}</div>
		                    </div>
		                    <div class="right-side">
		                    </div>
	                    </div>

                        <div class="column-item" v-if="user_data.orientation">
                            <div class="left-side">
                                <div class="property-name">Ориентация</div>
                                <div class="property-value">{{user_data.orientation.name}}</div>
                            </div>
                            <div class="right-side">
                            </div>
                        </div>
                        <div class="column-item" v-if="user_data.family_status">
                            <div class="left-side">
                                <div class="property-name">Семейное положение</div>
                                <div class="property-value">{{user_data.family_status.name}}</div>
                            </div>
                            <div class="right-side">
                            </div>
                        </div>
                        <div class="column-item" v-if="user_data.children">
                            <div class="left-side">
                                <div class="property-name">Дети</div>
                                <div class="property-value">{{user_data.children.name}}</div>
                            </div>
                            <div class="right-side">
                            </div>
                        </div>
                        <div class="column-item" v-if="user_data.education">
                            <div class="left-side">
                                <div class="property-name">Образование</div>
                                <div class="property-value">{{user_data.education.name}}</div>
                            </div>
                            <div class="right-side">
                            </div>
                        </div>
                        <div class="column-item" v-if="user_data.work_status">
                            <div class="left-side">
                                <div class="property-name">Занятость</div>
                                <div class="property-value">{{user_data.work_status.name}}</div>
                            </div>
                            <div class="right-side">
                            </div>
                        </div>
                        <div class="column-item" v-if="user_data.occupations && user_data.occupations.length">
                            <div class="left-side">
                                <div class="property-name">Род занятий</div>
                                <div class="property-value" v-for="i in user_data.occupations">{{i.name}}</div>
                            </div>
                            <div class="right-side" v-if="user_data.occupations && user_data.occupations.length">
                            </div>
                        </div>
                        <div class="column-item" v-if="user_data.interests && user_data.interests.length">
                            <div class="left-side">
                                <div class="property-name">Интересы</div>
                                <div class="property-value" v-for="i in user_data.interests">{{i.name}}</div>
                            </div>
                            <div class="right-side" v-if="user_data.interests && user_data.interests.length">
                            </div>
                        </div>
                        <div class="underline-link-wrapper" v-if="user_data.facebook_url">
                            <div class="underline-controls underline-link"><a :href="user_data.facebook_url" target="_blank">Facebook</a></div>
                            <!--<button href="" class="def-btn">Создать персональный вопрос</button>-->
                        </div>
                        <div class="underline-link-wrapper" v-if="user_data.vk_url">
                            <div class="underline-controls underline-link"><a :href="user_data.vk_url" target="_blank">Vkontakte</a></div>
                            <!--<button href="" class="def-btn">Создать персональный вопрос</button>-->
                        </div>
                    </div>
                </div>
            </div>

            <gallery-section v-if="id" ref="gallery-section" :user-id="id"></gallery-section>

            <div class="user-profile-posts-content" id="activities">
                <ul class="nav nav-tabs mb-3">
                    <li
                            v-for="tab in tabs"
                            :key="tab.name"
                            :class="['nav-item', { active: currentTab.name === tab.name }]"
                            @click="changetab(tab)">
                        <a class="nav-link" :class="[{ active: currentTab.name === tab.name }]" >
                            <span>{{ tab.name }}</span>
                        </a>
                    </li>
                </ul>
                <div class="section-wrapper">
                    <div class="section-content_wrapper">
                        <div class="content-wrapper" :style="{ 'min-height': activitiesMinHeight + 'px' }">
                            <div>
                                <DynamicScroller :items="userActivities" :min-item-size="600" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="2000">
                                    <template v-slot="{ item, index, active }">
                                        <DynamicScrollerItem :item="item" :active="active" :data-index="index" :size-dependencies="[item]">
                                            <QuestionPost v-if="item.type !== 101" :item="item" :list="userActivities" :block-title="item.title" :has-delete="false" :has-edit="false" :topic-global-id="item.topic_global_id" :has-margin="20" :delete-after-submit="false" :is-feed="false" :read-only="true" :show-avatars="true" :show-title-avatar="true" :blurred-option="true"></QuestionPost>
                                            <ImagePost v-else :item="item" :list="userActivities" :block-title="item.title" :has-margin="20" :isFeed="true" :show-avatars="true"></ImagePost>
                                        </DynamicScrollerItem>
                                    </template>
                                </DynamicScroller>
                            </div>
                        </div>
                    </div>
                    <div id="questions-right-sidebar" v-if="counters">
                        <div class="topic-wrapper">
                            <div class="topic-item" :class="{'active-item': c.active}" v-for="c in counters" @click="setActivityType(c)">
                                <span class="description">{{c.name}}</span>
                                <span class="amount">{{c.count}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="view-personal-question" size="lg" hide-footer id="view-personal-question" :no-close-on-backdrop="true" title="Персональные вопросы">
            <inner-circle-pq v-if="user_data" :is-modal="true" :user-data="user_data"></inner-circle-pq>
        </b-modal>

        <question-details-modal ref="question-details-modal" :is-question-feed="true" :topic-global-id="null"></question-details-modal>
    </div>
</template>

<script>
    import {questionsService, usersService} from "../../../_api";
    import questionDetailsModal from "../../../components/_shared/modals/_PostDetailedModal";
    import InnerCircle from "../../../components/questions/personal-questions/innerCircle";
    import avatar from "../../../components/_shared/Avatar";
    import {handleAWSImage} from "../../../_helpers/aws-handler";
    import moment from "moment";
    import PersonProgress from "../../../components/_shared/UserProgress";
    import QuestionPost from "../../../components/feed/QuestionPostBody";
    import EventBus from "../../../_helpers/event-bus";
    import {parsePostBody} from "../../../_helpers/post-body-parser";
    import gallerySection from "../../../components/_shared/GallerySection";
    import ImagePost from "../../../components/feed/ImagePostBody";
    import StartChatBtn from "@/components/_shared/StartChatBtn";

    export default {
        name: 'user-profile',
        props: {},
        data () {
            return {
                activitiesMinHeight: 0,
                id: null,
                rm: Math.random(),
                user_data: null,
                counters: null,
                userActivities: [],
                listOfPQ: [],
                loadIndexThreshold: 10,
                loadIndex: 10,
                page: 0,
                currentPage: 1,
                activeActivityID: null,
                tabs: [
                    {
                        name: 'ВСЯ АКТИВНОСТЬ',
                        id: 1,
                        activity_type: null
                    }, {
                        name: 'Схожие ответы ',
                        id: 2,
                        activity_type: 1
                    }, {
                        name: 'Несхожие ответы ',
                        id: 3,
                        activity_type: 0
                    }
                ],
                currentTab: null,
                similarList: [],
                loadingSimilar: false
            }
        },
        components: {
	        StartChatBtn,
            ImagePost,
            QuestionPost,
            PersonProgress,
            questionDetailsModal,
            avatar,
            'inner-circle-pq': InnerCircle,
            gallerySection
        },
        computed: {
            fromRoute() {
                return this.$route.query.from
            },
            hasAnswer() {
                return this.userActivities.filter((o) => {return o.has_answer})
            }
        },
        methods: {
            changetab(tab) {
                this.currentTab = tab;
                this.activitiesMinHeight = 1000;
                this.getUserActivity(this.activeActivityID, this.currentTab.activity_type, true);
            },
            openModalWithSimilarQ(item) {
                this.similarList = [];
                console.log(item);
                if (item.similar !== 0 || item.difference !== 0) {
                    this.loadingSimilar = true;
                    questionsService.getSimilarQuestion({
                        user_id: this.user_data.id,
                        topic_id: item.topic_id,
                        page: 1,
                        perPage: 100
                    }).then(res => {
                        console.log(res);
                        if (res && res.data.length) {
                            res.data.forEach((obj, index) => {

                                switch (obj.type) {
                                    case 1:
                                        obj['title'] = 'Вопрос дня';
                                        obj['is_question_of_the_day'] = true;
                                        obj['topic_global_id'] = 9;
                                        break;
                                    case 2:
                                        obj['title'] = 'Основной вопрос';
                                        obj['topic_global_id'] = 7;
                                        break;
                                    case 3:
                                        obj['title'] = 'Персональный вопрос';
                                        obj['topic_global_id'] = 8;
                                        break;
                                    default:
                                        obj['title'] = 'Основной вопрос';
                                        obj['topic_global_id'] = 7;
                                        break;
                                }

                                if (obj.redirected === 1) {
                                    obj['title'] = 'Вопрос от друзей Up&Up';
                                }

                                obj['question_id'] = obj.id;
                                obj['id'] = obj.unique_id;
                                obj['show_item'] = true;
                                obj['is_important'] = (obj.is_important) ? obj.is_important : false;
                                obj['bg_color'] = obj.color || '#c1debe';
                                obj['visibility'] = (obj.visibility) ? obj.visibility : 1;
                                obj['is_radio'] === 1 ? obj['selected'] = null : obj['selected'] = [];
                                obj['has_answer'] = obj.answers.some(i => {
                                    if (i.my_checked) {
                                        obj['selected'] = i;
                                    }
                                    return i.my_checked;
                                });
                                obj.answers.forEach(i => {
                                    i['checked'] = i.my_checked;
                                });
                                obj['has_answer_mode'] = obj['has_answer'];
                                obj['avatar_parsed'] = this.user_data.avatar_parsed;
                                this.$forceUpdate();
                                console.log(obj);
                                this.similarList.push(obj);
                            });
                            // console.log(data);
                            this.$refs['question-details-modal'].showModal('main', this.similarList[0], this.similarList);
                        }

                        setTimeout(() => {
                            this.loadingSimilar = false;
                        }, 500)
                    })
                }
            },
            handleScroll(startIndex, endIndex) {
                if (endIndex === this.loadIndex) {
                    this.loadIndex += this.loadIndexThreshold;
                    this.getUserActivity(this.activeActivityID, this.currentTab.activity_type);
                }
            },
            showPQModal() {
                console.log('qweqweqweqwe');
                if (this.user_data.personal_question_count > 0) {
                    this.$refs['view-personal-question'].show();
                }
            },
            getUserData(reload) {
                console.log(this.id);
                return new Promise(((resolve, reject) => {
                    usersService.getUsersData({
                        user_id: this.id
                    }).then((res) => {
                        this.user_data = res;
                        if (this.user_data.birthday) {
                            this.user_data.birthday = moment().diff(this.user_data.birthday, 'years',false);
                        }
                        handleAWSImage(this.user_data.avatar_135).then((res) => {
                            this.$set(this.user_data, 'avatar_parsed', res);
                            this.$forceUpdate();
                        });
                        handleAWSImage(this.user_data.avatar_36).then((res) => {
                            this.$set(this.user_data, 'avatar_parsed_36', res);
                            this.$forceUpdate();
                        });
                        //this.tabs[1].name = this.tabs[1].name + this.user_data.name.toUpperCase();
                        this.getUserActivityCounters(reload);

                        this.$forceUpdate();
                        console.log(res);
                        resolve(this.user_data);
                    })
                }))
            },
            startChat() {
                this.loadingChat = true;
                usersService.getChatIdAPI(this.user_data).then((res) => {
                    this.loadingChat = false;
                    if (res) {
                        this.$emit('startChat', res);
                        if (this.isModal) {
                            if (this.$route.query.t && this.$route.query.t !== res) {
                                this.$router.push({query: {'t': res}});
                            }
                        } else {
                            this.$router.push({ path: '/messages', query: { t: res, ff: true } });
                        }
                    }
                })
            },
            askQuestion() {
                this.$router.push({path: '/questions/personal-questions',  query: { user_id: this.user_data.id }});
            },
            sendFriendRequest() {
                usersService.sendFriendRequestAPI({
                    id: this.user_data.id
                }).then((res) => {
                    if (res) {
                        this.user_data['is_friend_request'] = 1;
                        this.$forceUpdate();
                    }
                })
            },
            resetPage() {
                this.page = 0;
                this.listOfPQ = [];
            },
            getUserActivity(activity_id, is_similar, reload) {
                if (reload) {
                    this.page = 0;
                    this.userActivities = [];
                }
                usersService.getUserActivityAPI({
                    user_id: this.user_data.id,
                    activity_id: (is_similar !== null) ? null : activity_id,
                    page: (!this.page) ? 1 : this.page + 1,
                    is_similar: is_similar
                }).then((res) => {
                    if (res && res.data.length) {
                        res.data.forEach((item,index) => {
                            parsePostBody(item).then(res_q => {
                                console.log(res_q.type);
                                if (res_q.type === 101) {
                                    res_q['show_item'] = true;
                                    res_q['title'] = `${res_q.author.name} добавил фото`;
                                    res_q.photos.forEach((obj_c, index) => {
                                        obj_c['photo_parsed'] = null;
                                        obj_c['photo_parsed_min'] = null;
                                        handleAWSImage(obj_c.photo).then(response => {
                                            obj_c['photo_parsed'] = response;
                                            obj_c['photo_parsed_min'] = response;
                                            this.$forceUpdate();
                                        });
                                        this.$forceUpdate();
                                    });
                                    if (res_q.author) {
                                        res_q.author['avatar_parsed'] = null;
                                        handleAWSImage(res_q.author.avatar_36).then(res => {
                                            res_q.author['avatar_parsed'] = res;
                                            this.$forceUpdate();
                                        })
                                    }
                                } else {
                                    switch (res_q.type) {
                                        case 1:
                                            res_q['title'] = 'Вопрос дня';
                                            res_q['is_question_of_the_day'] = true;
                                            res_q['topic_global_id'] = 9;
                                            break;
                                        case 2:
                                            res_q['title'] = 'Основной вопрос';
                                            res_q['topic_global_id'] = 7;
                                            break;
                                        case 3:
                                            res_q.author ? res_q['title'] = `Персональный вопрос от пользователя ${res_q.author.name}` : res_q['title'] = `Персональный вопрос`;
                                            //item['title'] = `Персональный вопрос от пользователя ${item.author.name}`;
                                            res_q['topic_global_id'] = 8;
                                            break;
                                        default:
                                            res_q['title'] = 'Основной вопрос';
                                            res_q['topic_global_id'] = 7;
                                            break;
                                    }
                                    if (res_q.redirected === 1) {
                                        let users_name = '';
                                        res_q.who_asked.length === 1 ? users_name = 'Пользователь ' : 'Пользователи ';
                                        res_q.who_asked.forEach((p, index) => {
                                            if (res_q.who_asked.length === 1) {
                                                users_name += p.name;
                                            } else {
                                                users_name += `, ${p.name}`
                                            }
                                        });
                                        res_q['title'] = `${users_name} интересуется Вашим ответом на вопрос`;
                                    }
                                    res_q['show_item'] = true;
                                    res_q['has_answer'] = res_q.answers.some(i => {
                                        if (i.my_checked) {
                                            res_q['selected'] = i;
                                        }
                                        return i.my_checked;
                                    });
                                    res_q.answers.forEach(i => {
                                        i['checked'] = i.my_checked;
                                    });
                                    res_q['has_answer_mode'] = res_q['has_answer'];
                                    res_q['avatar_parsed'] = this.user_data.avatar_parsed_36;
                                }
                                this.userActivities.push(res_q);
                                /*if (index === 9 && this.hasAnswer.length < 10) {
                                    this.getUserActivity(this.activeActivityID, this.currentTab.activity_type)
                                }*/
                            });
                        });
                        console.log(this.userActivities);
                        if (this.currentTab.activity_type !== null) {
                            let el = document.getElementById('activities');
                            window.scroll({ top: el.offsetTop, left: 0, behavior: 'smooth' });
                        }

                        this.$forceUpdate();
                        this.page = this.page + 1;
                    }
                })
            },
            getUserActivityCounters(reload) {
                usersService.getUserActivityCounters({user_id: this.user_data.id}).then((res) => {
                    let countersArr = [{
                        name: 'Все',
                        type: 9999,
                        count: res.count,
                        active: true
                    },{
                        name: 'Ответы на вопросы',
                        type: 2,
                        count: res.answer_count,
                        active: false
                    }, {
                        name: 'Изменения ответов',
                        type: 1,
                        count: res.change_count,
                        active: false
                    }, {
                        name: 'Создание персонального вопроса',
                        type:3,
                        count: res.personal_count,
                        active: false
                    }];
                    this.counters = countersArr;
                    this.activeActivityID = this.counters[0].type;
                    this.getUserActivity(this.activeActivityID, this.currentTab.activity_type, reload);
                })
            },
            setActivityType(c) {
                this.page = 0;
                this.userActivities = [];
                this.loadIndex = 10;
                this.counters.forEach(obj => {
                    if (obj.type === c.type) {
                        obj.active = true;
                        this.activeActivityID = obj.type;
                        this.getUserActivity(this.activeActivityID, this.currentTab.activity_type);
                    } else {
                        obj.active = false;
                    }
                })
            },
            removeFromFriends(){
                let q = confirm('Are you sure?');
                if (q) {
                    usersService.removeFromFriendsAPI({
                        id: this.user_data.id
                    }).then((res) => {
                        this.user_data.is_friend = 0;
                    })
                }
            },
            goToMyNotAnswered() {
                this.$router.push({path: '/questions/main-questions'});
            },
            backToPrevRoute() {
                this.$router.push({path: this.fromRoute, query: { preLoad: true }});
            }
        },
        created() {
            this.id = this.$route.params.id;
            this.currentTab = this.tabs[0];
        },
        mounted() {
            EventBus.$on('loadMoreQuestions', (data) => {
                this.getUserActivity(this.activeActivityID, this.currentTab.activity_type);
            });
            this.getUserData();
        },
        beforeDestroy() {
            EventBus.$off('loadMoreQuestions');
        },
        watch: {
            id: function (res) {
                console.log(res);
            }
        },
        beforeRouteUpdate (to, from, next) {
            this.id = to.params.id;
            next();
            this.user_data = null;
            this.getUserData(true);
            window.scrollTo(0, 0)
        }
    }
</script>

<style lang="less" scoped>

    @import "../../../assets/styles/_vars.less";

    #user-profile {
        .user-profile-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .my-profile-header__backbutton {
                img {
                    width: 32px;
                    cursor: pointer;
                }
            }
            .my-profile-header__title {
                font-size: 14px;
                line-height: 24px;
                text-align: right;
                letter-spacing: -0.14px;
                text-transform: uppercase;
                color: #666666;
            }
            .my-profile-header__controls {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                width: 68%;
                .def-btn, #start-chat-btn, ::v-deep #start-chat-btn .def-btn {
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    width: 100%;
                    max-width: 150px;
	                min-width: 150px;
                    &.disabled {
                        background: rgba(0, 0, 0, 0.1)!important;
                        color: #A9BAD4!important;
                        cursor: not-allowed;
                    }
                }
            }
        }
        .user-profile-info-content {
            width: 100%;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 1px;
            margin-bottom: 34px;
            .content-block {
                width: 100%;
                display: flex;
                justify-content: space-between;
                background: #fff;
                padding: 34px;
                margin-bottom: 2px;
                /*&:first-child {
                    margin-top: 34px;
                }*/
                .column {
                    width: 30%;
                    .image-as-bg {
                        overflow: hidden;
                        margin: -88px auto 14px;
                    }
                    .user-description {
                        min-height: 19px;
                        font-size: 14px;
                        line-height: 16px;
                        color: #999999;
                        margin-bottom: 8px;
                    }
                    .person-progress {
                        margin-bottom: 15px;
                    }
                    .user-fullname {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 14px;
                        letter-spacing: 0.2px;
                        margin: 0px auto 12px;
                    }
                    .arrow-link {
                        font-size: 13px;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 17px;
                        flex-wrap: wrap;
                        position: relative;
                        padding-right: 10px;
                        text-align: left;
                        &.link {
                            cursor: pointer;
                        }
                        .arrow {
                            position: absolute;
                            right: 0;
                            top: 7px;
                            width: 6px;
                            height: 6px;
                            border-bottom: 1px solid @theme_color;
                            border-right: 1px solid @theme_color;
                            transform: rotate(-45deg);
                        }
                        .desc {
                            width: 100%;
                            text-align: left;
                            font-size: 11px;
                            color: #9b9b9b;
                            margin-top: 5px;
                        }
                        .green-text {
                            color: #5EBD9C;
                        }
                        .quantity {
                            font-size: 12px;
                        }
                    }
                    .underline-link-wrapper {
                        text-align: left;
                        .underline-controls {
                            font-size: 12px;
                            line-height: 14px;
                            margin-bottom: 17px;
                            display: inline-block;
                        }
                        .def-btn {
                            margin-bottom: 14px;
                            font-size: 9px;
                            width: 100%;
                        }
                    }
                    .column-title {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        .title {
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 24px;
                            letter-spacing: -0.11px;
                            text-transform: uppercase;
                            color: #666666;
                        }
                        .icon-wrapper {
                            min-width: 25px;
                            text-align: center;
                            .icon {
                                background: transparent url('../../../assets/img/pencil-blue.svg') center no-repeat;
                                width: 12px;
                                height: 12px;
                                display: inline-flex;
                                cursor: pointer;
                            }
                        }
                    }
                    .column-item {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 14px;
                        text-align: left;
                        &.link-item {
                            cursor: pointer;
                        }
                        .left-side {
                            width: 60%;
                            .property-name {
                                font-size: 11px;
                                line-height: 18px;
                                letter-spacing: 0.0846154px;
                                color: #666666;
                            }
                            .property-value {
                                font-size: 13px;
                                line-height: 18px;
                                letter-spacing: 0.1px;
                                word-break: break-word;
                            }
                            .person-progress-topics {
                                margin-bottom: 15px;
                            }
                        }
                        .right-side {
                            width: auto;
                            min-width: 25px;
                            display: flex;
                            text-align: center;
                            justify-content: flex-end;
                            align-items: center;
                            span {
                                font-size: 13px;
                                text-decoration: underline;
                                &.similar {
                                    color: #5EBD9C;
                                }
                                &.difference {
                                    color: #D6585B;
                                    margin-left: 3px;
                                }
                            }
                        }
                    }
                }
                .column-left {
                    width: 30%;
                    text-align: left;
                    .title {
                        font-size: 22px;
                        line-height: 24px;
                        letter-spacing: 0.22px;
                    }
                    .subtitle {
                        font-size: 13px;
                        line-height: 15px;
                        color: #999999;
                    }
                }
                .column-right {
                    width: 65%;
                    padding-top: 20px;
                    .range-data-wrapper {
                        text-align: right;
                        .range-data-item {
                            display: flex;
                            justify-content: flex-end;
                            padding-bottom: 10px;
                            &:first-child {
                                .range-wrapper {
                                    position: relative;
                                    &:before {
                                        content: 'Меньше';
                                        position: absolute;
                                        left: 0px;
                                        top: -20px;
                                        font-size: 11px;
                                        line-height: 18px;
                                        letter-spacing: 0.0846154px;
                                        color: #666
                                    }
                                    &:after {
                                        content: 'Больше';
                                        position: absolute;
                                        right: 0px;
                                        top: -20px;
                                        font-size: 11px;
                                        line-height: 18px;
                                        letter-spacing: 0.0846154px;
                                        color: #666
                                    }
                                }
                            }
                            .psychological-feature {
                                font-size: 13px;
                                line-height: 18px;
                                letter-spacing: 0.1px;
                                color: @theme_color;
                            }
                            .range-wrapper {
                                margin-left: 30px;
                                padding-top: 8px;
                                .value-bar {
                                    width: 100%;
                                    min-width: 140px;
                                    position: relative;
                                    height: 1px;
                                    background: #666;
                                    .slider {
                                        position: absolute;
                                        left: 0;
                                        width: 10px;
                                        height: 10px;
                                        margin: -5px 0 0 -5px;
                                        border-radius: 50%;
                                        background: #666;
                                    }
                                }
                            }
                            .visibility-wrapper {
                                margin-left: 25px;
                                span {
                                    &.icon {
                                        display: inline-flex;
                                        width: 15px;
                                        height: 15px;
                                        &.all {
                                            background: transparent url('../../../assets/img/eye-icon.svg') center no-repeat;
                                            background-size: contain;
                                        }
                                        &.nobody {
                                            background: transparent url('../../../assets/img/secret.svg') center no-repeat;
                                            background-size: contain;
                                        }
                                        &.friends {
                                            background: transparent url('../../../assets/img/circle.svg') center no-repeat;
                                            background-size: 8px;
                                        }
                                    }
                                }
                            }
                        }
                        .edit-visibility {
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
        .user-profile-posts-content {
            width: 100%;
            .section-title {
                margin: 26px;
                text-align: left;
                h2 {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    text-transform: uppercase;
                    color: #666666;
                    margin: 0;
                }
            }
            .section-wrapper {
                display: flex;
                flex-wrap: wrap;
                .section-content_wrapper {
                    width: 68%;
                    .content-wrapper {
	                    ::v-deep #question {
		                    max-width: unset;
	                    }
                        .add-post {
                            box-shadow: 0 0 6px -4px black;
                            border-radius: 1px;
                            background: #FFFFFF;
                            margin-bottom: 24px;

                            .post-header {
                                padding: 20px 38px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .post-header-title {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    flex-wrap: wrap;

                                    img {
                                        width: 36px;
                                        height: 36px;
                                        border-radius: 100%;
                                        margin-right: 13px;
                                    }
                                }

                                .post-add-wrapper {
                                    width: 100%;

                                    textarea {
                                        outline: none;
                                        border: none;
                                        font-size: 12px;
                                        width: 100%;
                                        padding: 3px 5px;
                                        resize: none;
                                        overflow: hidden;
                                        height: 100%;
                                        vertical-align: middle;

                                        &:placeholder {
                                            font-size: 12px;
                                            line-height: 14px;
                                            color: #999999;
                                        }
                                    }
                                }

                                .post-visibility {
                                    font-size: 8px;
                                    line-height: 18px;
                                    color: #666666;
                                    font-weight: 400;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;

                                    span {
                                        &.icon {
                                            display: inline-flex;
                                            width: 12px;
                                            height: 9px;
                                            background: transparent url("../../../assets/img/eye-icon.svg") center no-repeat;
                                            background-size: contain;
                                        }
                                    }
                                }
                            }
                        }
                        .post-item-wrapper {
                            padding-bottom: 18px;
                            margin-bottom: 24px;

                            .post-item {
                                box-shadow: 0 0 6px -4px black;
                                border-radius: 1px;
                                background: #FFFFFF;
                                margin-bottom: 24px;

                                .post-header {
                                    padding: 20px 38px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    .post-header-title {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        flex-wrap: wrap;

                                        img {
                                            width: 36px;
                                            height: 36px;
                                            border-radius: 100%;
                                            margin-right: 13px;
                                        }

                                        .text-wrapper {
                                            display: flex;
                                            align-items: flex-end;

                                            p {
                                                margin: 0;

                                                &.user-name {
                                                    font-size: 13px;
                                                    font-weight: 500;
                                                    margin-right: 5px;
                                                }

                                                &.post-type-title {
                                                    font-size: 12px;
                                                    font-weight: 400;
                                                    color: #333333e6;
                                                }
                                            }
                                        }
                                    }

                                    .post-header-time {
                                        font-size: 11px;
                                        color: #999999;
                                        font-weight: 400;
                                    }
                                }

                                .post-body-wrapper {
                                    .post-body {
                                        padding: 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 18px;
                                        letter-spacing: 0.1px;
                                    }
                                }

                                .post-footer {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    flex-wrap: wrap;
                                    padding: 20px 38px;

                                    .post-counters {
                                        display: flex;

                                        .post-footer-likes {
                                            display: flex;
                                            margin-right: 15px;
                                            font-size: 12px;
                                            font-weight: 500;
                                            align-items: center;

                                            span {
                                                &.icon {
                                                    width: 24px;
                                                    height: 24px;
                                                    background-image: url("../../../assets/img/Radio button gray.svg");
                                                    display: flex;
                                                }
                                            }
                                        }

                                        .post-footer-comments {
                                            display: flex;
                                            font-size: 12px;
                                            margin-right: 15px;
                                            font-weight: 500;
                                            align-items: center;

                                            span {
                                                &.icon {
                                                    display: flex;
                                                    width: 24px;
                                                    height: 24px;
                                                    background-image: url("../../../assets/img/Comment Gray.svg");
                                                    margin-right: 3px;
                                                }
                                            }
                                        }

                                        .post-footer-visibility {
                                            display: flex;
                                            font-size: 12px;
                                            font-weight: 500;
                                            align-items: center;

                                            span {
                                                &.icon {
                                                    display: flex;
                                                    margin-right: 3px;

                                                    &.all {
                                                        width: 24px;
                                                        height: 24px;
                                                        background: transparent url('../../../assets/img/eye-icon.svg') center no-repeat;
                                                        background-size: 20px;
                                                    }

                                                    &.nobody {
                                                        width: 16px;
                                                        height: 24px;
                                                        background: transparent url('../../../assets/img/secret.svg') center no-repeat;
                                                        background-size: 14px;
                                                    }

                                                    &.friends {
                                                        width: 16px;
                                                        height: 24px;
                                                        background: transparent url('../../../assets/img/circle.svg') center no-repeat;
                                                        background-size: 12px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .post-actions {
                                        button {
                                            text-transform: uppercase;
                                            border-radius: 35px;
                                            border: 1px solid lightgrey;
                                            background-color: transparent;
                                            color: #333333;
                                            text-align: center;
                                            padding: 7px 30px;
                                            font-size: 10px;
                                            font-weight: 500;

                                            &:focus {
                                                outline: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                #questions-right-sidebar {
                    width: 30%;
                    margin-left: 2%;
                    .topic-wrapper {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        position: -moz-sticky;
                        position: -o-sticky;
                        position: -ms-sticky;
                        position: sticky;
                        position: -webkit-sticky;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        top: 94px;
                        .topic-item {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            font-size: 14px;
                            padding: 2px 10px;
                            border-radius: 30px;
                            margin-bottom: 5px;
                            cursor: pointer;
                            &:hover {
                                background-color: #f0f0f0;
                            }
                            &.active-item {
                                cursor: auto;
                                font-weight: 500;
                                color: #000000;
                                .description {
                                    color: #000000;
                                }
                            }
                            .description {
                                width: 80%;
                                text-align: left;
                                color: #5E6F8F;
                            }
                            .amount {
                                width: 20%;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
    ::v-deep #view-personal-question {
        .modal-dialog {
            max-width: 520px;
        }
        .modal-body {
            background: #f4f4f4;
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #user-profile {
            .user-profile-posts-content {
                .nav {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    &::-webkit-scrollbar{
                        height: 2px!important;
                        width: 2px!important;
                    }
                    li {
                        a {
                            position: relative;
                            margin-bottom: 4px;
                            padding: 5px 25px 0 25px;
                            white-space: nowrap;
                        }
                    }
                }
                .section-wrapper {
                    flex-wrap: wrap-reverse;
                    .section-content_wrapper {
                        width: 100%;
                    }
                    #questions-right-sidebar {
                        width: 100%;
                        margin-bottom: 10px;
                        .topic-wrapper {
                            overflow-x: auto;
                            flex-direction: unset;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: flex-start;
                            &::-webkit-scrollbar{
                                height: 2px!important;
                                width: 2px!important;
                            }
                            .topic-item {
                                border: 1px solid lightgray;
                                font-size: 13px;
                                &:not(:last-child) {
                                    margin-right: 5px;
                                }
                                .description {
                                    width: max-content;
                                    margin-right: 10px;
                                }
                                .amount {
                                    width: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media  (max-width: 576px) and (orientation: portrait) {
        #user-profile {
            .user-profile-header {
                position: relative;
                &.has-back-btn {
                    padding: 0 0 0 40px;
                }
                .my-profile-header__backbutton {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                .my-profile-header__controls {
                    flex-wrap: wrap;
                    width: 100%;
                    button {
                        width: 100%;
                        max-width: 320px;
                        margin: 0 auto 10px auto!important;
                    }
                }
            }
            .user-profile-info-content {
                .content-block {
                    padding: 14px;
                    flex-wrap: wrap;
                    .column {
                        width: 100%;
                        .image-as-bg {
                            margin: 0 auto 10px auto;
                        }
                    }
                }
            }
            .user-profile-posts-content {
                .nav {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    &::-webkit-scrollbar{
                        height: 2px!important;
                        width: 2px!important;
                    }
                    li {
                        a {
                            position: relative;
                            margin-bottom: 4px;
                            padding: 5px 25px 0 25px;
                            white-space: nowrap;
                        }
                    }
                }
                .section-wrapper {
                    flex-wrap: wrap-reverse;
                    .section-content_wrapper {
                        width: 100%;
                    }
                    #questions-right-sidebar {
                        width: 100%;
                        margin-bottom: 10px;
                        .topic-wrapper {
                            overflow-x: auto;
                            flex-direction: unset;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: flex-start;
                            &::-webkit-scrollbar{
                                height: 2px!important;
                                width: 2px!important;
                            }
                            .topic-item {
                                border: 1px solid lightgray;
                                font-size: 13px;
                                &:not(:last-child) {
                                    margin-right: 5px;
                                }
                                .description {
                                    width: max-content;
                                    margin-right: 10px;
                                }
                                .amount {
                                    width: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>


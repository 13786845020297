<template>
    <div id="my-pq" :class="{'is-modal': isModal}">
        <DynamicScroller :items="list" :min-item-size="200" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="1000">
            <template v-slot="{ item, index, active }">
                <DynamicScrollerItem :item="item" :active="active" :data-index="index" :size-dependencies="[item]">
                    <QuestionPost :item="item" :list="list" :block-title="item.title" :has-delete="false" :has-edit="false" :topic-global-id="8" :has-margin="0" :delete-after-submit="false" :is-feed="false" :read-only="false" :show-avatars="true" :show-title-avatar="true" :has-confirmation-modal="true"></QuestionPost>
                </DynamicScrollerItem>
            </template>
        </DynamicScroller>
    </div>
</template>

<script>
    import {questionsService} from "../../../_api/questions.service";
    import QuestionPost from "../../feed/QuestionPostBody";
    import {handleAWSImage} from "../../../_helpers/aws-handler";
    import {parsePostBody} from "../../../_helpers/post-body-parser";

    export default {
        name: 'inner-circle-pq',
        props: {
            topicId: Number,
            isModal: Boolean,
            userData: Object,
            searchStr: String,
            activeSearch: Boolean
        },
        data () {
            return {
                loading: true,
                list: [],
                loadIndexThreshold: 10,
                loadIndex: 10,
                page: 0
            }
        },
        components: {
            QuestionPost
        },
        computed() {
          console.log('this.list',this.list)
        },
        methods: {
            handleScroll(startIndex, endIndex) {
                if (endIndex === this.loadIndex) {
                    this.loadIndex += this.loadIndexThreshold;
                    (!this.searchStr.length) ? this.getQuestions(this.topicId) : this.searchQuestions(this.topicId, this.searchStr);
                }
            },
            getQuestions(top_id) {
                let method = null;
                if (this.isModal) {
                    method = questionsService.getPersonalQuestionsAskedByUser;
                } else {
                    method = questionsService.getPersonalQuestionsInnerCircle;
                }
                method({
                    page: (!this.page) ? 1 : this.page + 1,
                    topic_id: top_id !== 9999999 ? top_id : null,
                    user_id: this.isModal ? this.userData.id : null
                }).then((res) => {
                    if (res) {
						console.log(res);
                        res.data.forEach(obj => {
                            parsePostBody(obj).then(res_q => {
                                if (this.isModal) {
                                    res_q['has_answer'] = res_q.answers.some(i => {
                                        if (i.my_checked) {
                                            i['checked'] = i.my_checked;
                                            res_q['selected'] = i;
                                        }
                                        return i.checked;
                                    });
                                } else {
                                    res_q['has_answer'] = res_q.answers.some(i => {
                                        if (i.checked) {
                                            res_q['selected'] = i;
                                        }
                                        return i.checked;
                                    });
                                }

                                res_q['avatar_parsed'] = null;
                                if (res_q.author && !res_q.who_asked.length) {
                                    res_q['title'] = `Персональный вопрос от пользователя ${res_q.author.name}`;
                                    handleAWSImage(res_q.author.avatar_36).then(img => {
                                        res_q['avatar_parsed'] = img;
                                    });
                                } else if (res_q.author && res_q.who_asked.length > 1) {
                                  let users = res_q.who_asked.map((o) => {return o.name}).filter(el => el !== res_q.author.name );
                                  res_q['title'] = `Персональный вопрос от пользователя ${res_q.author.name} который вам переслал(-и) ${users.join(', ')}`;
                                  handleAWSImage(res_q.author.avatar_36).then(img => {
                                    res_q['avatar_parsed'] = img;
                                  });
                                }else if (res_q.author && res_q.who_asked.length  ) {
                                    let users = res_q.who_asked.map((o) => {return o.name}).filter(el => el !== res_q.author.name );
                                    res_q['title'] = `Персональный вопрос от пользователя ${res_q.author.name}  `;
                                    handleAWSImage(res_q.author.avatar_36).then(img => {
                                        res_q['avatar_parsed'] = img;
                                    });
                                }

                                if (this.userData) {
                                    res_q['title'] = `Персональный вопрос от пользователя ${this.userData.name}`;
                                    res_q['avatar_parsed'] = this.userData.avatar_parsed_36;
                                }

                                res_q['has_answer_mode'] = res_q['has_answer'];
                                this.list.push(res_q);
                              console.log('this.list', this.list)
                                this.$forceUpdate();
                            });
                        });
                        this.page = res.current_page;
                    }
                    this.loading = false;
                });
            },
            searchQuestions(top_id, search_str) {
                questionsService.searchPersonalQuestionsAPI({
                    page: (!this.page) ? 1 : this.page + 1,
                    perPage: 10,
                    topic_id: top_id !== 9999999 ? top_id : null,
                    query: search_str,
                    type: null, // opened/closed/blank if all,
                    is_my: 0
                }).then((res) => {
                    if (res) {
                        res.data.forEach(obj => {
                            parsePostBody(obj).then(res_q => {
                                res_q['has_answer'] = res_q.answers.some(i => {
                                    if (i.checked) {
                                        res_q['selected'] = i;
                                    }
                                    return i.checked;
                                });
                                if (res_q.from_user_question) {
                                    res_q['title'] = `Персональный вопрос от пользователя ${res_q.from_user_question[0].name}`;
                                } else {
                                    res_q['title'] = `Персональный вопрос`;
                                }
                                res_q['avatar_parsed'] = null;
                                if (res_q.author && !res_q.who_asked.length) {
                                    res_q['title'] = `Персональный вопрос от пользователя ${res_q.author.name}`;
                                    handleAWSImage(res_q.author.avatar_36).then(img => {
                                        res_q['avatar_parsed'] = img;
                                    });
                                } else if (res_q.author && res_q.who_asked.length) {
                                    let users = res_q.who_asked.map((o) => {return o.name});
                                    res_q['title'] = `Персональный вопрос от пользователя ${res_q.author.name} который вам переслал(-и) ${users.join(', ')}`;
                                    handleAWSImage(res_q.author.avatar_36).then(img => {
                                        res_q['avatar_parsed'] = img;
                                    });
                                }
                                res_q['has_answer_mode'] = res_q['has_answer'];
                                this.list.push(res_q);
                            });

                        });
                        if (res.data.length) {
                            this.page += 1;
                        }
                    }
                    this.loading = false;
                    // console.log(this.listOfOpenQuestions);
                });
            },
            resetPage(id) {
                this.page = 0;
                this.list = [];
                this.loadIndex = this.loadIndexThreshold;
            }
        },
        created() {
            if (!this.activeSearch) {
                this.getQuestions(this.topicId);
            }
        },
        mounted() {
            //this.getQuestions(this.topicId);
        },
        watch: {}
    }
</script>

<style lang="less" scoped>
    #my-pq {
        &.is-modal {
            max-height: 80vh;
            overflow-y: auto;
        }
    }
</style>




